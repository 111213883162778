export const ClientFormUserRole = {
  Approver: 30,
  Contributor: 20,
  Owner: 50,
  Validator: 40,
  Viewer: 10,
} as const;

export type ClientFormUserRoleNames = 'approver' | 'contributor' | 'owner' | 'validator' | 'viewer';

export type ClientFormUserRoleValues = (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole];

export const ClientFormUserRoleKeys = {
  [ClientFormUserRole.Approver]: 'common:form-role.approver',
  [ClientFormUserRole.Contributor]: 'common:form-role.contributor',
  [ClientFormUserRole.Owner]: 'common:form-role.owner',
  [ClientFormUserRole.Validator]: 'common:form-role.validator',
  [ClientFormUserRole.Viewer]: 'common:form-role.viewer',
} as const;

export const ClientFormUserRoleRequiredness = {
  any: 0,
  required: 1,
  optional: 2,
} as const;

export const ClientFormUserRoleRequirednessKeys = {
  [ClientFormUserRoleRequiredness.any]: 'common:form-role-requiredness.any',
  [ClientFormUserRoleRequiredness.required]: 'common:form-role-requiredness.required',
  [ClientFormUserRoleRequiredness.optional]: 'common:form-role-requiredness.optional',
} as const;
